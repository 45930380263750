@import "../../variables.scss";

@media only screen and (max-width: $redlab-breakpoint1) {
  .Form {
    padding: 5vw 4vw 1vw 4vw !important;
  }

  .FormItem {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}

.Form {
  background-color: $redlab-background-grey;
  padding: 5vw 12vw 1vw 12vw;

  > .FormTitle {
    white-space: pre-wrap;
    font-size: 48px;
    margin-bottom: 50px;
    color: $redlab-white;
    @include no-select;
  }

  > .FormFeedBack {
    font-size: 12px;
    color: $redlab-green;
    text-align: left;
    margin-left: 50px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out 0s;
  }

  .FormFeedBackError {
    color: $redlab-red !important;
  }

  .FormFeedBackHidden {
    opacity: 0;
    transition: opacity 0s ease-in-out 0s;
  }

  > .FormContent {
    padding: 15px;
    margin: auto;
    @include flexbox;
    @include flexDirection(column);
    align-content: center;
    justify-content: center;

    > .FormRow {
      @include flex(1);
      @include flexbox;
      align-content: center;
      justify-content: center;

      > .FormItem {
        @include flex(1);
        @include flexbox;
        align-content: stretch;
        justify-content: stretch;
        margin-left: 35px;
        margin-right: 35px;
        margin-bottom: 35px;

        > .FormControl {
          font-size: 20px;
          font-family: "Gilroy-Light";
          color: $redlab-white;
          width: 100%;
          background: none;
          border: none;
          border-bottom: 1px solid $redlab-red;
          outline: none;
          padding: 0;
          height: 50px;

          @include placeholder {
            color: $redlab-white;
            opacity: 1;
          }
        }

        input:-webkit-autofill {
          @include autocompleteColor(transparent, $redlab-black);
        }

        > .FormControlTextArea {
          height: 100px;
        }

        > .FormButton {
          cursor: pointer;
          width: 100px;
          border: none;
          font-family: "Gilroy-ExtraBold";
          font-size: 32px;
          margin: auto;
          transition: color 0.5s ease-in 0s;

          &:hover {
            color: $redlab-red !important;
          }
        }

        > .FormButtonDisabled {
          cursor: default;
          color: $redlab-grey;

          &:hover {
            color: $redlab-grey !important;
          }
        }
      }
    }
  }

  > .FormReCAPTCHA {
    display: flex;
    align-items: center;
    justify-content: center;

    > iframe {
      height: auto;
      width: auto;
    }
  }
}
