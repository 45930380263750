@import '../../../variables.scss';

@media only screen and (max-width: $redlab-breakpoint1) {
    .Tools {
        > .datGUI {
            margin-right: 10vw !important;
        }
    }
}

.Tools {
    position: absolute;
    top: 30px;
    right: 5px;
    color: black;
    @include flexbox;
    @include flexDirection(column);
    text-align: left;
    align-items: flex-start;
    justify-content: space-between;

    > .datGUI {
        position: relative;
        margin-right: 25vw;
        margin-top: 2em;
        right: 0;
        top: 0;
        
        > ul {
            > li {
                @include flexbox;

                > .dg {
                    width: 100%;
                }
            }
        }
    }
}


