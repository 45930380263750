@import '../../../variables.scss';

.HomeWheel {
    width: 100%;

    path {
        stroke: $redlab-red;
        stroke-width: 20px;
    }
}
