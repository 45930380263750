@import "../../../variables.scss";

.ServicesPageItems {
  color: $redlab-white;

  > .ServicesPageItemsList {
    @include flexbox;
    @include flexDirection(column);
    position: relative;
    align-items: stretch;
    justify-content: stretch;

    > .ServicesPageItemsContent {
      color: $redlab-white;

      > .ServicesPageItemsContentBody {
        @include flexbox;
        @include flexDirection(column);
        align-items: stretch;
        justify-content: stretch;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        padding: 6vw 2vw 6vw 2vw;
        @include no-select;

        > .ServicesPageItemsContentTitle {
          @include flex(0.4);
          margin: 1vw;
          @include flexbox;
          align-items: flex-end;
          justify-content: center;
          @include no-select;

          > .ServicesPageItemsContentTitleBody {
            width: 100%;
            margin-bottom: 30px;
            font-size: 32px;
            text-align: left;
          }
        }

        > .ServicesPageItemsContentInfo {
          @include flex(1);
          margin: 1vw;
        }
      }
    }

    > .ServicesPageItemsServiceHeader {
      @include linearGradient(
        180deg,
        $redlab-background-dark 10%,
        $redlab-background-grey 100%
      );
    }

    > .ServicesPageItemsService {
      background-color: $redlab-background-grey;
    }
  }
}
