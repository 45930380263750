@import "../../variables.scss";

.Services {
  background-color: $redlab-background-dark;
  position: relative;
  height: 100vh;
  background: rgb(25, 25, 25);

  > .ServicesBackground {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  > .ServicesContent {
    position: relative;
    z-index: 150;
    width: 100%;
    height: 100%;
    @include flexbox;
    @include flexDirection(column);
    align-items: stretch;
    justify-content: stretch;

    > .ServicesBody {
      @include flex(1);
      @include flexbox;
      align-items: stretch;
      justify-content: stretch;

      .ServicesRows {
        width: 100%;
        @include flexbox;
        @include flexDirection(column);
        align-items: stretch;
        justify-content: stretch;

        .ServicesRow1 {
          @include flex(1);
          @include flexbox;
          margin: 8vw;

          > .ServicesListContent {
            @include flex(1);
            @include flexbox;
            @include flexDirection(column);
            align-items: flex-start;
            justify-content: center;
          }

          > .ServicesHorizontalSpacer {
            @include flex(1);
          }

          > .ServicesInfoContent {
            @include flex(0.8);
            @include flexbox;
            @include flexDirection(column);
            @include no-select;
            align-content: center;
            justify-content: center;
            text-align: left;

            > .ServicesInfoTitle {
              @include flexbox;
              justify-content: flex-start;
              font-size: 30px;
              white-space: pre-wrap;

              > div {
                align-self: flex-end;
              }
            }

            > .ServicesInfoText {
              @include flexbox;
              justify-content: center;
              font-family: "Gilroy-Light";
              font-size: 16px;
              line-height: 28px;
              margin: 2vw 0 2vw 0;

              > div {
                align-self: center;
              }
            }

            > .ServicesInfoButton {
              @include flexbox;
              justify-content: flex-start;
              pointer-events: all;
              font-size: 19px;

              > a {
                color: $redlab-white;
                @include no-select;
                background: none;
                border: none;
                outline: none;
                padding: 0;
                cursor: pointer;
                align-self: flex-start;

                > .ServicesInfoButtonContent {
                  @include borderRadius;
                  width: 155px;
                  height: 56px;
                  @include flexbox;
                  text-align: center;
                  align-items: center;
                  justify-content: center;
                  pointer-events: all;
                  transition: background-color 0.5s ease-in 0s, color 0.5s ease-in 0s;
                  background-color: $redlab-red;
                  color: $redlab-white;

                  &:hover {
                    background-color: $redlab-white !important;
                    color: $redlab-red !important;
                  }

                  > div {
                    align-self: center;
                    justify-self: center;
                  }
                }
              }
            }
          }
        }
      }

      .ServicesRow2 {
        @include flex(0);
      }
    }
  }
}
