@import "../../../variables.scss";

@media only screen and (max-width: $redlab-breakpoint1) {
  .ServicesPageHeroHomeLogo {
    > a {
      > img {
        max-width: 60px !important;
      }
    }
  }

  .ServicesPageHeroContentContent {
    padding: 0px 2vw 50px 2vw !important;
  }
}

.ServicesPageHero {
  color: $redlab-white;

  > .ServicesPageHeroOverlay {
    width: 100%;
    height: 100%;
    z-index: 90;
    position: absolute;
    pointer-events: none;
    @include flexbox;
    @include flexDirection(column);

    > .ServicesPageHeroLogo {
      pointer-events: all;
      position: absolute;
      z-index: 70;
      left: 0;
      right: 0;
      top: 5vh;

      > a {
        overflow: auto;
        display: table;
        margin: auto;
        cursor: pointer;

        > img {
          max-width: 80px;
        }
      }
    }
  }

  > .ServicesPageHeroContent {
    position: relative;
    width: 100vw;
    height: 100vh;

    > .ServicesPageHeroContentBackground {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;

      > .ServicesPageHeroContentVideo {
        position: relative;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 0.5;
        }
      }
    }

    > .ServicesPageHeroContentContent {
      position: relative;
      z-index: 50;
      align-items: stretch;
      justify-content: stretch;
      color: $redlab-white;
      white-space: pre-wrap;
      text-align: left;
      width: 100%;
      height: 100%;
      @include flexbox;
      @include flexDirection(column);
      @include flex(1);

      > .ServicesPageHeroContentText {
        margin-bottom: 30px;
        @include flex(1);
        @include flexbox;
        justify-content: center;
        align-self: center;
        padding-left: 20%;
        padding-right: 20%;
        white-space: pre-wrap;
        text-align: center;
      }
    }
  }
}
