@import "./variables.scss";

@font-face {
  font-family: Gilroy-ExtraBold;
  src: url("./assets/fonts/Gilroy-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: Gilroy-Light;
  src: url("./assets/fonts/Gilroy-Light.otf") format("opentype");
}

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Gilroy-ExtraBold", sans-serif;
  font-size: 12px;
  text-align: center;
  color: $redlab-white;
  background-color: $redlab-background-dark;
}

.App {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  text-align: center;

  > .AppMenu {
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    height: 105px;
    z-index: 1001;
    @include linearGradient(
      180deg,
      $redlab-background-dark 0%,
      $redlab-transparent 60%
    );
  }

  > .AppBody {
    margin-top: 0px;
  }
}

.GradientSeparator {
  height: 200px;
  @include linearGradient(
    180deg,
    $redlab-background-transition 0%,
    $redlab-background-grey 90%
  );
}

.GradientSeparatorInv {
  height: 50px;
  @include linearGradient(
    180deg,
    $redlab-background-dark 0%,
    $redlab-background-transition 90%
  );
}

.GradientSeparatorFallback {
  height: 150px;
  @include linearGradient(
    180deg,
    $redlab-background-transition 0%,
    $redlab-background-grey 90%
  );
}

.GradientSeparatorInvFallback {
  height: 50px;
  @include linearGradient(
    180deg,
    $redlab-background-dark 0%,
    $redlab-background-transition 90%
  );
}
