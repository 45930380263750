@import "../../variables.scss";

@media only screen and (max-width: $redlab-breakpoint1) {
  .HomeFallbackLogo {
    margin-bottom: 10vh !important;
  }

  .HomeFallbackLanguageItem {
    font-size: 22px !important;
  }

  .HomeFallbackTextContent {
    font-size: 48px !important;
  }

  .HomeWheelContent {
    padding-bottom: 15vh !important;
  }
}

.HomeFallbackFadeOut {
  opacity: 0;
  background-color: $redlab-background-dark;
  transition: opacity 0.1s ease-in-out 0s;
}

.HomeFallbackFadeIn {
  opacity: 1;
  transition: opacity 1s ease-in-out 0s;
}

.HomeFallback {
  height: 100vh;
  position: relative;
  @include radialGradient(circle, $redlab-radial-grey 0%, $redlab-black 50%);

  > .HomeFallbackOverlay {
    width: 100%;
    height: 100%;
    z-index: 90;
    position: absolute;
    pointer-events: none;
    @include flexbox;
    @include flexDirection(column);

    > .HomeFallbackLogo {
      position: absolute;
      z-index: 70;
      left: 0;
      right: 0;
      top: 5vh;

      > img {
        max-width: 80px;
      }
    }

    > .HomeFallbackText {
      width: 100%;
      height: 100%;
      @include flexbox;
      @include flexDirection(column);
      @include flex(1);

      > .HomeFallbackTextContent {
        @include flex(1);
        font-size: 119px;
        @include flexbox;
        justify-content: center;
        align-self: center;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 5vh;
        white-space: pre-wrap;
      }
    }

    > .HomeFallbackOverlayObjects {
      width: 100%;
      height: 100%;
      z-index: 70;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  > .HomeFallbackContent {
    pointer-events: all;
    position: relative;
    z-index: 50;
    width: 100%;
    height: 100%;

    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
