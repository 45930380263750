@import "../../../variables.scss";

@media only screen and (max-width: $redlab-breakpoint1) {
  .ServicesPageInfo {
    @include flexDirection(column, !important);
  }

  .ServicesPageInfoOutputs {
    margin-top: 15px;
  }
}

.ServicesPageInfo {
  text-align: left;
  color: inherit;
  @include no-select;
  white-space: pre-wrap;
  font-family: "Gilroy-Light";
  font-size: 18px;
  line-height: 36px;
  @include flexbox;
  @include flexDirection(row);

  .ServicesPageInfoText {
    @include flex(1);
  }

  .ServicesPageInfoOutputs {
    text-align: center;
    padding: 0 50px 0 50px;
  }
}
