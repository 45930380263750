@import "../../variables.scss";

@media only screen and (max-width: $redlab-breakpoint1) {
  .AboutTitle {
    font-size: 20px !important;
  }

  .AboutText {
    font-size: 20px !important;
  }
}

.About {
  width: 100vw;
  height: 100vh;
  margin: auto;
  background-color: $redlab-background-dark;

  > .AboutInfo {
    width: 100%;
    height: 100%;
    @include flexbox;
    text-align: left;
    color: $redlab-white;
    pointer-events: none;

    > .AboutInfoContent {
      align-self: center;
      padding-left: 8vw;
      padding-right: 8vw;

      > .AboutTitle {
        font-family: "Gilroy-Light";
        font-size: 32px;
        margin-bottom: 5vh;
      }

      > .AboutText {
        font-size: 32px;
        margin-bottom: 2vh;
        white-space: pre-wrap;
      }
    }
  }
}
