@import "../../variables.scss";

.MenuFadeOut {
  opacity: 0;
}

.MenuFadeIn {
  opacity: 1;
  pointer-events: all;
}

.Menu {
  pointer-events: none;
}

.MenuIcon {
  color: $redlab-white;
  pointer-events: all;
  cursor: pointer;
  align-self: flex-end;
  position: absolute;
  top: 35px;
  right: 35px;
  z-index: 100;

  &:hover {
    color: $redlab-red !important;
  }
}

.MenuOverlay {
  width: 100vw !important;
  height: 100vh !important;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out 0s;

  .MenuCanvas {
    width: 100% !important;
    height: 100% !important;
    background-color: black;
    visibility: visible !important;
    @include flexbox;
    @include flexDirection(column);
    justify-content: center;
    align-items: center;

    > .MenuContent {
      @include flexbox;
      justify-content: center;
      align-items: center;

      > .MenuItems {
        @include flexbox;
        @include flexDirection(column);

        > .MenuList {
          @include flexbox;
          @include flexDirection(column);
          transition: opacity 2.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
          opacity: 1;
        }

        > .MenuListHide {
          transition: opacity 0s linear 0s;
          opacity: 0;
        }

        .MenuTitle {
          pointer-events: all;
          font-family: "Gilroy-ExtraBold";
          font-size: 29px;
          color: $redlab-white;
          cursor: pointer;
          background: none;
          border: none;
          outline: none;
          padding: 0;
          margin-top: 50px;
          margin-bottom: 15px;
          text-align: left;
        }

        .MenuItem {
          pointer-events: all;
          font-family: "Gilroy-ExtraBold";
          font-size: 29px;
          color: $redlab-white;
          cursor: pointer;
          background: none;
          border: none;
          outline: none;
          padding: 0;
          text-align: center;
          text-decoration: none;
          margin: 0px 0px 15px 0px;

          > .MenuLanguageItem {
            color: $redlab-grey-light;
            cursor: inherit;
            background: inherit;
            border: inherit;
            outline: inherit;
            padding: inherit;
            font-family: inherit;
            font-size: inherit;
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 50px;

            &:hover {
              color: $redlab-red !important;
            }
          }

          > .MenuLanguageItemSelected {
            color: $redlab-white;
          }

          &:hover {
            color: $redlab-red !important;
          }
        }
      }
    }
  }
}
