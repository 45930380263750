@import '../../../variables.scss';

@media only screen and (max-width: $redlab-breakpoint1) {
  .FpsDisplay {
    margin-right: 10vw !important;
  }
}

.FpsDisplay {
    position: absolute;
    margin-right: 25vw;
    top: 5px;
    right: 5px;
    width: 76px;
    height: 46px;
    color: black;
    text-align: left;

    > div {
      position: relative !important;
    }
}