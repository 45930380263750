@import '../../variables.scss';

@media only screen and (max-width: $redlab-breakpoint1) {
  .HomeLogo {
    margin-bottom: 10vh !important;

    > img {
      max-width: 60px !important;
    }
  }

  .HomeWheelContent {
    padding-bottom: 15vh !important;
  }
}

.HomeFadeOut {
  opacity: 0;
  background-color: $redlab-background-dark;
  transition: opacity 0.1s ease-in-out 0s;
}

.HomeFadeIn {
  opacity: 1;
  transition: opacity 1s ease-in-out 0s;
}

.Home {
  width: 100%;
  height: 100%;
  position: relative;
  @include radialGradient(circle, $redlab-radial-grey 0%, $redlab-black 50%);

  > .HomeOverlay {
    width: 100%;
    height: 100%;
    z-index: 90;
    position: absolute;
    pointer-events: none;
    @include flexbox;
    @include flexDirection(column);

    > .HomeLogo {
      position: absolute;
      z-index: 70;
      left: 0;
      right: 0;
      top: 5vh;

      > img {
        // max-width: 80px;
        max-width: 16rem !important;
        margin-top: 1rem !important;
      }
    }

    > .HomeText {
      width: 100%;
      height: 100%;
      @include flexbox;
      @include flexDirection(column);
      @include flex(1);

      > .HomeTextContent {
        @include flex(1);
        @include flexbox;
        justify-content: center;
        align-self: center;
        padding-left: 10%;
        padding-right: 10%;
        white-space: pre-wrap;
        text-align: center;
      }
    }

    > .HomeWheelContent {
      @include flex(0);
      height: 50px;
      justify-content: center;
      align-self: center;
      padding-bottom: 5vh;

      .HomeWheelText {
        color: $redlab-red;
      }
    }

    > .HomeOverlayObjects {
      width: 100%;
      height: 100%;
      z-index: 70;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  > .HomeAbout {
    pointer-events: none;
    width: 100%;
    height: 100%;
    z-index: 70;
    position: absolute;
    background-color: $redlab-white;
  }

  > .HomeContent {
    pointer-events: all;
    position: relative;
    z-index: 50;
    width: 100%;
    height: 100%;

    > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
