@import '../../variables.scss';

.Footer {
  width: 100%;
  background-color: $redlab-black;
  color: $redlab-white;
  @include flexbox;
  @include flexDirection(column);
  align-content: center;
  justify-content: center;
  text-align: center;

  > .FooterContent {
    margin: 4vh 2vh 6vh 2vh;
    @include flex(1);
    @include flexbox;
    @include flexDirection(column);
    align-content: center;
    justify-content: center;
    font-size: 28px;
    font-family: 'Gilroy-ExtraBold';

    > .FooterItem {
      @include flex(1);
      margin-bottom: 15px;

      > .FooterLink {
        color: inherit;
        @include no-select;
        font: inherit;
        cursor: pointer;
        transition: color 0.5s ease-in 0s;

        > svg {
          color: inherit !important;
        }

        &:hover {
          color: $redlab-red !important;
        }
      }

      small {
        font-size: 1rem;
      }
    }

    > .FooterLogo {
      > img {
        height: 1.6rem;
        filter: brightness(0) invert(1);
      }
    }

    > .FooterSocial {
      width: 100%;

      > .FooterSocialItem {
        margin-left: 10px;
        margin-right: 10px;

        > .FooterIcon {
          color: white;
        }
      }
    }
  }
}
