$redlab-breakpoint0: 0px;
$redlab-breakpoint1: 550px;
$redlab-breakpoint2: 1000px;

$redlab-red: #c91f2e;
$redlab-green: #009a00;
$redlab-grey: #9f9f9f;
$redlab-grey-light: #383838;
$redlab-white: #ffffff;
$redlab-black: #000000;
$redlab-transparent: #00000000;
$redlab-background-grey: #383838;
$redlab-background-dark: #191919;
$redlab-background-transition: #121212;
$redlab-background-darker: #0000008a;
$redlab-radial-grey: #272727;
$redlab-shadow-grey: #2d2d2dd9;
$redlab-helper-spot: #ff0000;
$redlab-helper-point: #fc03cf;

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root("::-webkit-input-placeholder") {
    @content;
  }

  @include optional-at-root(":-moz-placeholder") {
    @content;
  }

  @include optional-at-root("::-moz-placeholder") {
    @content;
  }

  @include optional-at-root(":-ms-input-placeholder") {
    @content;
  }
}

@mixin autocompleteColor($background, $color) {
  -webkit-box-shadow: 0 0 0 30px $background inset;
  -webkit-text-fill-color: $color;
  box-shadow: 0 0 0 30px $background inset;
  caret-color: $color;
  font-style: italic;
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
}

@mixin linearGradient($degrees, $from, $to, $inset:"") {
  background-color: $from unquote($inset);
  background: $from unquote($inset);
  background: -moz-linear-gradient($degrees, $from, $to) unquote($inset);
  background: -webkit-linear-gradient($degrees, $from, $to) unquote($inset);
  background: linear-gradient($degrees, $from, $to) unquote($inset);
}

@mixin radialGradient($degrees, $from, $to) {
  background-color: $from;
  background: $from;
  background: -moz-radial-gradient($degrees, $from, $to);
  background: -webkit-radial-gradient($degrees, $from, $to);
  background: radial-gradient($degrees, $from, $to);
}

@mixin borderRadius($value: 32px) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  border-radius: $value;
}

@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -ms-flex: $values;
  -webkit-flex: $values;
  flex: $values;
}

@mixin flexDirection($direction, $inset:"") {
  @if $direction == column {
    -webkit-flex-direction: vertical unquote($inset);
    -moz-flex-direction: vertical unquote($inset);
    -ms-flex-direction: column unquote($inset);
    -webkit-flex-direction: column unquote($inset);
    flex-direction: column unquote($inset);
  } @else {
    -webkit-flex-direction: horizontal unquote($inset);
    -moz-flex-direction: horizontal unquote($inset);
    -ms-flex-direction: row unquote($inset);
    -webkit-flex-direction: row unquote($inset);
    flex-direction: row unquote($inset);
  }
}
