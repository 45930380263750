@import "../../variables.scss";

@media only screen and (max-width: $redlab-breakpoint1) {
  .TrailerVideo {
    max-width: 90vw !important;
    margin: 4vw 0 4vw 0;
  }
}

.Trailer {
  @include flexbox;
  align-items: center;
  justify-content: center;
  background-color: $redlab-background-grey;
  cursor: default;
  padding: 2vw 2vw 6vw 2vw;

  .TrailerContent {
    transition: transform 0.5s ease-in-out 0s, opacity 2.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;

    > .TrailerVideo {
      position: relative;
      @include flex(1);
      max-width: 50vw;
      @include flexbox;

      img {
        margin: auto;
        width: 100%;
      }
    }
  }

  .TrailerContentHide {
    transition: transform 0.5s ease-in-out 0s, opacity 0s linear 0s;
  }
}
