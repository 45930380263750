@import "../../../variables.scss";

.RedVideo {
  width: 100%;
  height: 100%;
  
  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.RedVideoShadow {
  width: 100%;
  height: 100%;

  video {
    outline: 1px solid $redlab-background-grey;
    box-shadow: 0px 20px 20px 5px $redlab-shadow-grey;
  }
}

.RedVideoFallback {
  width: 100%;
  height: 100%;
  background-color: $redlab-white;
}
