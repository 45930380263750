@import "../../../../variables.scss";

@media only screen and (max-width: $redlab-breakpoint1) {
  .TiltTextItem {
    font-size: 48px !important;
  }

  .HomeSubTextItem {
    font-size: 12px !important;
  }
}

.TiltTextItem {
  color: $redlab-white;
  transition: transform 1s ease-in-out 0s;
  text-align: center;
  font-size: 119px;
}

.HomeSubTextItem {
  color: $redlab-white;
  transition: transform 1s ease-in-out 0s;
  margin-top: 20px;
  font-size: 16px;
}
