@import "../../../variables.scss";

.ServicesListItem {
  color: $redlab-grey;
  pointer-events: all;
  border: none;
  outline: none;
  margin-bottom: 10px;
  text-align: left;
  font-size: 30px;
  background-image: linear-gradient($redlab-red, $redlab-red);
  background-repeat: no-repeat;
  background-position: 0% 60%;
  background-size: 0% 0.3em;
  transition: background-size 0.5s ease-in-out 0s;
  @include no-select;
}

.ServicesListItemSelected {
  background-size: 80% 0.3em;
  color: $redlab-white ;
}

.ServicesListItemText {
  margin-left: 20px;
}
