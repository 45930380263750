@import "../../../variables.scss";

.ServicesMobileListItem {
  color: $redlab-red;
  pointer-events: all;
  border: none;
  outline: none;
  text-align: center;
  font-size: 30px;
  white-space: pre-wrap;
  @include no-select;
  overflow: hidden;
}

.ServicesMobileListItemText {
  width: 100%;
}
