@import "../../variables.scss";

.Technologies {
  background-color: $redlab-background-grey;
  color: $redlab-white;
  padding: 4vw 8vw 2vw 8vw;

  > .TechnologiesContent {
    @include flexbox;
    padding-top: 2vh;
    margin-bottom: 50px;

    > .TechnologiesTitle {
      text-align: left;
      color: $redlab-red;
      font-size: 24px;
      @include flexbox;
      @include flex(0);

      > .TechnologiesTitleContent {
        @include flex(0);
        margin-right: 5px;
      }

      > .TechnologiesTitleCaret {
        @include flex(1);
      }
    }

    > .TechnologiesList {
      @include flex(1);
      width: 60%;
      height: 100%;
      text-align: center;
    }
  }
}
