@import "../../variables.scss";

.ServicesMobile {
  background-color: $redlab-background-dark;
  position: relative;
  height: 100vh;
  background: rgb(25,25,25);

  > .ServicesMobileBackground {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  > .ServicesMobileContent {
    position: relative;
    z-index: 150;
    width: 100%;
    height: 100%;
    @include flexbox;
    @include flexDirection(column);
    align-items: stretch;
    justify-content: stretch;

    > .ServicesMobileBody {
      @include flex(1);
      @include flexbox;
      align-items: stretch;
      justify-content: stretch;

      .ServicesMobileRows {
        width: 100%;
        @include flexbox;
        @include flexDirection(column);
        align-items: stretch;
        justify-content: stretch;

        .ServicesMobileRow1 {
          @include flex(0.2);
          margin: 35vw 0vw 0vw 0vw;

          > .ServicesMobileListContent {
            text-align: center;
          }
        }

        .ServicesMobileRow2 {
          @include flex(1);
        }

        .ServicesMobileRow3 {
          @include flex(0);
          @include flexbox;

          > .ServicesInfoContent {
            @include flex(1);
            @include flexbox;
            @include flexDirection(column);
            @include no-select;
            align-content: center;
            justify-content: center;
            text-align: center;

            > .ServicesInfoTitle {
              @include flex(0.5);
              @include flexbox;
              justify-content: center;
              font-size: 18px;

              > div {
                align-self: flex-end;
              }
            }

            > .ServicesInfoText {
              @include flex(1);
              @include flexbox;
              justify-content: center;
              font-family: "Gilroy-Light";
              font-size: 16px;
              margin: 5vw 5vw 5vw 5vw;
              text-align: center;

              > div {
                align-self: center;
              }
            }

            > .ServicesInfoButton {
              @include flex(0);
              @include flexbox;
              justify-content: center;

              > a {
                color: $redlab-white;
                @include no-select;
                background: none;
                border: none;
                outline: none;
                padding: 0;
                cursor: pointer;
                align-self: flex-start;

                > .ServicesInfoButtonContent {
                  @include borderRadius;
                  width: 125px;
                  height: 32px;
                  @include flexbox;
                  text-align: center;
                  align-items: center;
                  justify-content: center;
                  pointer-events: all;
                  transition: background-color 0.5s ease-in 0s, color 0.5s ease-in 0s;
                  background-color: $redlab-red;
                  color: $redlab-white;

                  &:hover {
                    background-color: $redlab-white !important;
                    color: $redlab-red !important;
                  }

                  > div {
                    align-self: center;
                    justify-self: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
