@import "../../../variables.scss";

.TechnologiesItem {
  cursor: default;
  margin-top: 6px;

  > .TechnologiesItemText {
    font-family: 'Gilroy-Light';
    font-size: 20px;
    @include no-select;
    text-align: inherit;
  }
}
